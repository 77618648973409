import { Box, Container, Typography, Grid } from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { makeStyles } from "@mui/styles";
import { fontSizeTheme, fontSizeThemeTitle } from "./../../theme/ThemeFontSize";

const useStyles = makeStyles((theme) => {
	return {
		image: {
			height: "70%",
			width: "100%",
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover",
			borderRadius: "4%",
		},
		title: {
			fontFamily: "Pushster",
			fontSize: "4.5em",
			color: "#fafafa",
			fontWeight: "700",
			textAlign: "center",
		},
	};
});

export default function MobileSection() {
	const classes = useStyles();
	return (
		<Box
			sx={{
				position: "relative",
				display: "flex",
				flexDirection: "row",
				px: 1,
				py: 4,
			}}>
			<Grid container>
				<Grid item xs={12} sm={12} md={6}>
					<Container
						maxWidth="sm"
						id="ventajas"
						sx={{
							display: "flex",
							flexDirection: "column",
							p: 2.5,
						}}>
						<Typography
							fontSize={fontSizeThemeTitle}
							sx={{ pb: 1 }}
							variant="h6"
							color="#042D74"
							fontWeight={600}
							align="center"
							gutterBottom>
							Sosmuni para los ciudadanos
						</Typography>
						<Typography
							fontSize={fontSizeTheme}
							variant="body1"
							color="text.secondary"
							fontWeight={500}
							align="left"
							gutterBottom>
							El aplicativo móvil de Sosmuni brinda las siguientes ventajas a
							sus usuarios:
						</Typography>
						<Container
							maxWidth="md"
							sx={{
								display: "flex",
								flexDirection: "column",
								p: 2,
							}}>
							<Container
								maxWidth="lg"
								sx={{
									display: "flex",
									flexDirection: "row",
								}}>
								<CheckCircleIcon fontSize="medium" sx={{ pt: 0.5 }} />
								<Typography
									fontSize={fontSizeTheme}
									sx={{ px: 1 }}
									variant="body1"
									color="text.secondary"
									align="justify"
									gutterBottom>
									Una plataforma digital de comunicación directa con sus
									autoridades.
								</Typography>
							</Container>
							<Container
								maxWidth="lg"
								sx={{
									display: "flex",
									flexDirection: "row",
								}}>
								<CheckCircleIcon fontSize="medium" sx={{ pt: 0.5 }} />
								<Typography
									fontSize={fontSizeTheme}
									sx={{ px: 1 }}
									variant="body1"
									color="text.secondary"
									align="justify"
									gutterBottom>
									La capacidad de recibir ayuda ante accidentes o situaciones de
									riesgo.
								</Typography>
							</Container>
							<Container
								maxWidth="lg"
								sx={{
									display: "flex",
									flexDirection: "row",
								}}>
								<CheckCircleIcon fontSize="medium" sx={{ pt: 0.5 }} />
								<Typography
									fontSize={fontSizeTheme}
									sx={{ px: 1 }}
									variant="body1"
									color="text.secondary"
									align="justify"
									gutterBottom>
									Reportar problemas vecinales como el mantenimiento de parques,
									recojo de basura, pistas en mal estado, etc.
								</Typography>
							</Container>
							<Container
								maxWidth="lg"
								sx={{
									display: "flex",
									flexDirection: "row",
								}}>
								<CheckCircleIcon fontSize="medium" sx={{ pt: 0.5 }} />
								<Typography
									fontSize={fontSizeTheme}
									sx={{ px: 1 }}
									variant="body1"
									color="text.secondary"
									align="justify"
									gutterBottom>
									Recibir actualizaciones sobre el estado de sus reportes
									generados, atendidos y resueltos.
								</Typography>
							</Container>
							<Container
								maxWidth="lg"
								sx={{
									display: "flex",
									flexDirection: "row",
								}}>
								<CheckCircleIcon fontSize="medium" sx={{ pt: 0.5 }} />
								<Typography
									fontSize={fontSizeTheme}
									sx={{ px: 1 }}
									color="text.secondary"
									align="justify"
									gutterBottom>
									La opción de enviar un SOS ante situaciones de peligro.
								</Typography>
							</Container>
						</Container>
					</Container>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={6}
					sx={{
						p: {
							lg: 3,
							md: 3,
							sm: 2,
							xs: 2,
						},
					}}>
					{/*
                    <ReactPlayer
                        className='react-player'
                        url='https://www.youtube.com/watch?v=Pkh8UtuejGw'
                        width='100%'
                        height='400px'
                        controls
                        />
                
                    */}
					<Container maxWidth="xl" sx={{ p: 3 }}>
						<img
							src="/images/mobile/mobile.webp"
							alt="Aplicación Móvil Sosmuni"
							className={classes.image}
						/>
					</Container>
				</Grid>
			</Grid>
		</Box>
	);
}

/*
Sosmuni está disponible para Android y iOS, de forma gratuita. Su uso es bastante intuitivo y amigable
EL aplicativo móvil Sosmuni brinda una serie de ventajas para los vecinos:

Disponer de una plataforma digital inteligente de comunicación con las autoridades.

Reportar y recibir ayuda acerca de diversos tipos de incidentes relacionados a seguridad ciudadana.

Reportar y recibir ayuda acerca de diversos tipos de incidentes relacionados a servicios a la comunidad: Mantenimiento y limpieza de parques, recojo de basura, etc.

Reportar y recibir ayuda acerca de diversos tipos de incidentes relacionados a Obras públicas como veredas, bermas y pistas en mal estado.

Reportar y recibir ayuda acerca de diversos tipos de incidentes relacionados a Obras privadas como construcciones ilegales, ruidos molestos, etc.
*/

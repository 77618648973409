import { Box, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { fontSizeTheme, fontSizeThemeTitle } from "../../theme/ThemeFontSize";

export default function PrivacyPoliticsDelta() {
	useEffect(() => {
		// Cambiar el título de la pestaña
		const originalTitle = document.title;
		document.title = "Delta dispatch - Privacidad";

		// Cambiar el favicon
		const favicon = document.querySelector('link[rel="icon"]');
		const originalFavicon = favicon.href;
		favicon.href = "/favicon_2.ico";

		// Revertir cambios al desmontar el componente
		return () => {
			document.title = originalTitle;
			favicon.href = originalFavicon;
		};
	}, []);
	return (
		<Box
			minHeight="70vh"
			sx={{
				position: "relative",
				display: "flex",
				flexDirection: "row",
				px: 1,
				py: 4,
			}}>
			<Container maxWidth="md">
				<Typography
					fontSize={fontSizeThemeTitle}
					sx={{ pb: 1 }}
					variant="h6"
					color="#042D74"
					fontWeight={800}
					align="center"
					gutterBottom>
					Delta Dispatch
				</Typography>
				<Typography
					fontSize={fontSizeThemeTitle}
					sx={{ pb: 1 }}
					variant="h5"
					color="#042D74"
					fontWeight={600}
					align="center"
					gutterBottom>
					Políticas de Privacidad
				</Typography>

				<Typography
					display="block"
					fontSize={fontSizeTheme}
					variant="body1"
					color="text.secondary"
					fontWeight={500}
					align="justify"
					gutterBottom
					style={{ lineHeight: "30px" }}>
					<strong>1. Introducción</strong>
					<br />
					Estos términos de la política de privacidad (en adelante, "Términos")
					son aplicables al uso de los servicios ofrecidos por la Municipalidad
					del distrito seleccionado, denominada en adelante "La Municipalidad",
					a través de la presente aplicación (en adelante, la "APP"). Al
					instalar y usar la APP, el usuario acepta explícitamente todos los
					Términos establecidos en este documento.
				</Typography>

				<Typography
					display="block"
					fontSize={fontSizeTheme}
					variant="body1"
					color="text.secondary"
					fontWeight={500}
					align="justify"
					gutterBottom
					style={{ lineHeight: "30px" }}>
					<strong>2. Recopilación de Datos Personales</strong>
					<br />
					La Municipalidad recopila y trata los siguientes tipos de datos
					personales a través de la APP:
					<ul>
						<li>
							<strong>Información Personal:</strong> Incluye datos como nombre,
							dirección de correo electrónico y número de teléfono.
						</li>
						<li>
							<strong>Ubicación Precisa:</strong> La APP puede recopilar datos
							de ubicación precisa para ofrecer servicios relacionados con la
							seguridad ciudadana.
						</li>
						<li>
							<strong>Mensajes:</strong> Se recopilan y almacenan los correos
							electrónicos enviados a través de la APP.
						</li>
						<li>
							<strong>Fotos y Videos:</strong> La APP puede acceder y almacenar
							fotos y videos tomados o seleccionados por el usuario.
						</li>
						<li>
							<strong>Archivos de Audio:</strong> La APP puede acceder y
							almacenar grabaciones de voz o archivos de sonido.
						</li>
					</ul>
				</Typography>

				<Typography
					display="block"
					fontSize={fontSizeTheme}
					variant="body1"
					color="text.secondary"
					fontWeight={500}
					align="justify"
					gutterBottom
					style={{ lineHeight: "30px" }}>
					<strong>3. Protección y Confidencialidad de Datos</strong>
					<br />
					La Municipalidad se compromete a proteger y garantizar la privacidad
					de los datos personales de sus usuarios, conforme a lo establecido en
					la Ley de Protección de Datos Personales (Ley N° 29733) y su
					Reglamento (Decreto Supremo N° 003-2013-JUS). Todos los datos
					proporcionados por los usuarios serán tratados de manera automatizada
					e incorporados en una o más bases de datos, de las cuales la
					Municipalidad es titular y responsable, conforme a los términos
					previstos por la ley.
				</Typography>

				<Typography
					display="block"
					fontSize={fontSizeTheme}
					variant="body1"
					color="text.secondary"
					fontWeight={500}
					align="justify"
					gutterBottom
					style={{ lineHeight: "30px" }}>
					<strong>4. Uso y Compartición de Datos</strong>
					<br />
					La Municipalidad puede utilizar y compartir los datos personales con
					autoridades y terceros autorizados por la ley, con el fin de cumplir
					con las obligaciones establecidas en las normas peruanas e
					internacionales, incluyendo las relacionadas con la prevención de
					lavado de activos y normas prudenciales. <br />
					Los datos personales incluyen toda la información que haya sido
					proporcionada y a los que se haya podido acceder de forma física, oral
					o electrónica a través de fuentes accesibles al público. Si se cuenta
					con la autorización respectiva, la municipalidad podrá incluir la
					información de los usuarios en sus bases de datos personales;
					requerida teniendo consideraciones establecidas en la Ley de
					Protección de datos Personales “Ley N° 29733” y su Reglamento aprobado
					por el Decreto Supremo N° 003-2013-JUS (“las normas de protección de
					datos personales”). Asimismo, se podrá ofrecer cualquiera de los
					servicios de la Municipalidad a través de cualquier medio escrito,
					verbal y/o informático.
				</Typography>

				<Typography
					display="block"
					fontSize={fontSizeTheme}
					variant="body1"
					color="text.secondary"
					fontWeight={500}
					align="justify"
					gutterBottom
					style={{ lineHeight: "30px" }}>
					<strong>5. Retención y Eliminación de Datos</strong>
					<br />
					<ul>
						<li>
							<strong>Retención de Datos:</strong> Los datos personales serán
							almacenados mientras sea necesario para cumplir con los fines
							descritos en esta política de privacidad o según lo exija la ley.
						</li>
						<li>
							<strong>Eliminación de Datos:</strong> Los usuarios pueden
							solicitar la eliminación de su cuenta y los datos asociados a
							través del enlace:{" "}
							<a
								href="https://sosmuni.com/deleteaccount"
								target="_blank"
								rel="noopener noreferrer">
								Eliminar Cuenta
							</a>
							. También pueden contactar al equipo de soporte para gestionar
							estas solicitudes.
						</li>
					</ul>
				</Typography>

				<Typography
					display="block"
					fontSize={fontSizeTheme}
					variant="body1"
					color="text.secondary"
					fontWeight={500}
					align="justify"
					gutterBottom
					style={{ lineHeight: "30px" }}>
					<strong>6. Actualizaciones de la Política de Privacidad</strong>
					<br />
					La Municipalidad se reserva el derecho de actualizar esta política de
					privacidad en cualquier momento. Los usuarios serán informados sobre
					cualquier cambio mediante notificaciones en la APP o a través de otros
					medios adecuados.
				</Typography>

				<Typography
					display="block"
					fontSize={fontSizeTheme}
					variant="body1"
					color="text.secondary"
					fontWeight={500}
					align="justify"
					gutterBottom
					style={{ lineHeight: "30px" }}>
					<strong>7. Contacto</strong>
					<br />
					Para cualquier consulta o solicitud relacionada con la privacidad de
					sus datos, los usuarios pueden ponerse en contacto con nosotros a
					través del correo electrónico:{" "}
					<a href="mailto:innova.msg@gmail.com">innova.msg@gmail.com</a> o
					visitando nuestra página web:{" "}
					<a
						href="https://sosmuni.com"
						target="_blank"
						rel="noopener noreferrer">
						https://sosmuni.com
					</a>
					.
				</Typography>
			</Container>
		</Box>
	);
}

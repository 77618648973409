import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import PropTypes from "prop-types";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import { Link as Scroll } from "react-scroll";

//const pages = ['Producto', 'Ventajas', 'Contáctanos'];
// const pages = [new PageNavigation('Producto', '/'), new PageNavigation('Ventajas', '/'), new PageNavigation('Contáctanos', '/contact')];

function HideOnScroll(props) {
	const { children, window } = props;
	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
	});

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
}

HideOnScroll.propTypes = {
	children: PropTypes.element.isRequired,
	window: PropTypes.func,
};

const useStyles = makeStyles((theme) => {
	return {
		appbar: {
			fontFamily: "Pushster", //Pushster  -- Montserrat Alternates
			fontSize: ".75em",
		},
		appbarTitle: {
			flexGrow: "1",
			color: "#fafafa",
		},
		image: {
			height: 40,
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover",
			flexGrow: 1,
		},
	};
});

function AppBarComponent(props) {
	const classes = useStyles();
	return (
		<HideOnScroll {...props}>
			<AppBar
				className={classes.appbar}
				position="fixed"
				sx={{ background: "#E5E5E5", boxShadow: "none" }}>
				<Toolbar component="nav">
					<Box
						sx={{
							//backgroundColor: 'primary.main',
							position: "relative",
							opacity: [0.8, 0.9, 0.9],
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
							flexGrow: 1,
						}}>
						<a href="/">
							<Container
								maxWidth="xs"
								sx={{
									m: 0,
									height: 35,
									width: 125,
									backgroundImage: `url(${"images/navbar.png"})`,
									position: "relative",
									//opacity: [0.8, 0.9, 0.9],
									backgroundRepeat: "no-repeat",
									backgroundSize: "cover",
								}}></Container>
						</a>
					</Box>
					<Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
						<Scroll to="inicio" smooth={true}>
							<Button
								key={"inicio"}
								//href={'/'}
								sx={{
									my: 0,
									mx: 1,
									color: "#042D74",
									display: "block",
									textTransform: "none",
								}}>
								<Typography variant="body1" color="#042D74" fontWeight={600}>
									{"Inicio"}
								</Typography>
							</Button>
						</Scroll>
						<Scroll to="ventajas" smooth={true}>
							<Button
								key={"Ventajas 2"}
								sx={{
									my: 0,
									mx: 1,
									color: "#042D74",
									display: "block",
									textTransform: "none",
								}}>
								<Typography variant="body1" color="#042D74" fontWeight={600}>
									{"Ventajas"}
								</Typography>
							</Button>
						</Scroll>

						<Scroll to="contactus" smooth={true}>
							<Button
								key={"contactanos"}
								//href={'/contact'}
								sx={{
									my: 0,
									mx: 1,
									color: "#042D74",
									display: "block",
									textTransform: "none",
								}}>
								<Typography variant="body1" color="#042D74" fontWeight={600}>
									{"Contáctanos"}
								</Typography>
							</Button>
						</Scroll>

						{/*
                        {pages.map((page) => (
                            <Button
                                key={page.name}
                                href={page.route}

                                sx={{ my: 0, mx: 1, color: '#042D74', display: 'block', textTransform: 'none' }}
                            >
                                <Typography variant="body1" color="#042D74" fontWeight={600}>
                                    {page.name}
                                </Typography>
                            </Button>
                        ))}
                     */}
					</Box>

					{/*  <h1 className={classes.appbarTitle}   >
                        Sosmuni
                    </h1>
                    */}
					<IconButton
						size="medium"
						color="primary"
						aria-label="facebook"
						href="https://www.facebook.com/sosmuni">
						<FacebookIcon />
					</IconButton>
					<IconButton
						size="medium"
						color="primary"
						aria-label="instagram"
						href="https://www.instagram.com/sosmuni/">
						<InstagramIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
		</HideOnScroll>
	);
}

export default AppBarComponent;
